<template>
  <v-card class="my-5">
    <v-toolbar color="green darken-2" dark>
      <v-toolbar-title class="row no-gutters">
        <v-col class="row no-gutters justify-center" cols="11">
          <span class="ml-10">Всего : {{ count }} </span>
        </v-col>
        <v-col class="row no-gutters justify-end justify-lg-center justify-md-center justify-sm-center" cols="1">
          <v-tooltip left>
            <template v-slot:activator="{ attrs, on }">
              <v-icon @click="download" v-bind="attrs" v-on="on" large>mdi-microsoft-excel</v-icon>
            </template>
            <span>Выгрузить в Excel</span>
          </v-tooltip>
        </v-col>
      </v-toolbar-title>
    </v-toolbar>
    <v-row class="pl-2 pr-14 py-2">
      <v-col cols="12" class="col col-lg-7 col-md-7 col-sm-7">
        <v-radio-group hide-details dense row v-model="reportType">
          <v-radio value="orders" label="По заказам"/>
          <v-radio value="stores" label="По заведениям"/>
          <v-radio value="dates" label="По датам"/>
          <v-radio value="storeDates" label="По зав. и по датам"/>
          <v-radio value="missed" label="Пропущ. заказы"/>
          <v-radio value="drivers" label="По курьерам"/>
          <v-radio value="transactions" label="По пополнениям"/>
        </v-radio-group>
      </v-col>
      <v-col cols="8" class="col col-lg-4 col-md-4 col-sm-4">
        <VueCtkDateTimePicker
            id="date"
            v-model="date"
            :range="temp"
            :firstDayOfWeek="week"
            :onlyDate="onlyDate"
            autoClose
            button-color="#3FC44F"
            class="mt-2"
            color="#3FC44F"
            format="MM-DD-YYYY"
            :formatted="formatted"
            label="Выберите промежуток времени"
            locale="ru"/>
      </v-col>
      <v-col cols="4" class="col col-lg-1 col-md-1 col-sm-1">
        <v-btn @click="filter" color="primary" ripple class="mt-2">
          Показать
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="reportType === 'orders'">
      <v-row dense class="hidden-xs-only">
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption pl-4">Номер</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Заведение</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption pl-4">Телефон кл.</span>
        </v-col>
        <v-col cols="3" class="row no-gutters">
          <v-col cols="6" class="row no-gutters justify-start">
            <span class="caption">Курьер</span>
          </v-col>
          <v-col cols="6" class="row no-gutters justify-center">
            <span class="caption">Начат/Завершен</span>
          </v-col>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Цена</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Комиссия</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Система безнал</span>
        </v-col>
      </v-row>
      <v-row no-gutters v-for="(order,index) in ordersList" :key="index" class="grey lighten-4 mx-1 mb-1">
        <v-col cols="12" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center text-no-wrap">
          <v-btn v-if="order.partner" x-small class="caption" color="blue lighten-3">
            {{ order.number }}
          </v-btn>
          <v-btn v-else text x-small class="caption">
            {{ order.number }}
          </v-btn>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 d-flex text-no-wrap">
          <v-col cols="6" class="row no-gutters justify-start align-center">
             <span class="caption">
            {{ order.name }}
          </span>
          </v-col>
          <v-col cols="6" class="row no-gutters justify-end align-center">
            <span class="caption">
              {{ order.distance }} м.
            </span>
          </v-col>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-center align-center text-no-wrap">
          <span class="caption">
            {{ order.client | normalizePhone }}
          </span>
        </v-col>
        <v-col cols="12" class="col col-lg-3 col-md-3 col-sm-3 row no-gutters d-flex align-center">
          <v-col cols="12"
                 class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-center justify-lg-start justify-md-start justify-sm-start">
            <span class="caption">
              {{ order.driver }}
            </span>
          </v-col>
          <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-center">
            <span class="caption">
              {{ order.started_at | timeFormat }}
          </span>
          </v-col>
          <v-col cols="12"
                 class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-center justify-lg-start justify-md-start justify-sm-start">
            <span class="caption">
              {{ order.vehicle_name.String }}
            </span>
          </v-col>
          <v-col cols="12" class="col col-lg-6 col-md-6 col-sm-6 row no-gutters justify-center">
            <span class="caption">
              {{ order.finished_at.Time | timeFormat }}
          </span>
          </v-col>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters d-flex align-center">
          <v-col cols="6" class="row no-gutters justify-end align-center">
             <span class="caption">
            {{ order.store }} с.
          </span>
          </v-col>
          <v-col cols="6" class="row no-gutters justify-start align-center">
            <span class="caption">
               + {{ order.delivery }} с.
            </span>
          </v-col>
          <v-col cols="12" class="row no-gutters justify-center align-center">
            <span class="caption">
              {{ order.total }} с.
            </span>
          </v-col>
        </v-col>
        <v-col cols="12" class="col col-lg-1 col-md-1 col-sm-1 row justify-center no-gutters d-flex align-center">
          <span class="caption">
              {{ order.store_commission }}c.
            </span>
        </v-col>
        <v-col v-if="order.payment_system !== 'NONE'" cols="12" class="col col-lg-1 col-md-1 col-sm-1 row no-gutters">
          <v-col cols="12" class="col d-flex justify-center align-center text-no-wrap">
          <span class="caption">
            {{ order.payment_id.Int64 }}
          </span>
          </v-col>
          <v-col cols="12" class="col d-flex justify-center align-center text-no-wrap">
          <span class="caption">
            {{ order.payment_system }}
          </span>
          </v-col>
        </v-col>
      </v-row>
      <v-pagination
          v-model="currentPage"
          :length="paginationCount"
          total-visible="9"
          @input="fetchOrders">
      </v-pagination>
    </div>
    <div v-if="reportType === 'stores'">
      <v-row class="mx-0 mb-4 mt-0">
        <v-col>
          <v-radio-group class="ma-0 pa-0" hide-details dense row v-model="partner">
            <v-radio :value=false label="Клиент"/>
            <v-radio :value=true label="Партнер"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row dense class="hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Заведение</span>
        </v-col>
        <v-col cols="1" class="row no-gutters">
          <v-col cols="6" class="row no-gutters justify-center">
            <span class="caption">Всего</span>
          </v-col>
          <v-col cols="6" class="row no-gutters justify-center">
            <span class="caption">Безнал</span>
          </v-col>
        </v-col>
        <v-col cols="2" class="row no-gutters">
          <v-col cols="3" class="row no-gutters justify-start">
            <span class="caption">ЦенаЗав.</span>
          </v-col>
          <v-col cols="3" class="row no-gutters justify-start">
            <span class="caption">комЗав.</span>
          </v-col>
          <v-col cols="3" class="row no-gutters justify-start">
            <span class="caption pl-1">Курьер</span>
          </v-col>
          <v-col cols="3" class="row no-gutters justify-center">
            <span class="caption pr-2">Всего</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5">
          <div v-for="order in storesList" :key="order.name" class="row no-gutters grey lighten-4 mx-1 mb-1">
            <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5 d-flex justify-center align-center text-no-wrap">
              <span class="caption">
                {{ order.name }}
              </span>
            </v-col>
            <v-col cols="4" class="col col-lg-2 col-md-2 col-sm-2 row no-gutters d-flex align-center">
              <v-col cols="6" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.count }}</span>
              </v-col>
              <v-col cols="6" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.cashless }}</span>
              </v-col>
            </v-col>
            <v-col cols="8" class="col col-lg-5 col-md-5 col-sm-5 row no-gutters d-flex align-center">
              <v-col cols="3" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.store }} с.</span>
              </v-col>
              <v-col cols="3" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.store_commission }} с.</span>
              </v-col>
              <v-col cols="3" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.delivery }} с.</span>
              </v-col>
              <v-col cols="3" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.total }} с.</span>
              </v-col>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-center">
          <piechart v-if="loaded" class="pie"></piechart>
        </v-col>
      </v-row>
      <v-pagination
          v-model="currentPage"
          :length="paginationCount"
          total-visible="9"
          @input="fetchStores">
      </v-pagination>
    </div>
    <div v-if="reportType === 'dates'">
      <v-row class="mx-0 mb-4 mt-0">
        <v-col>
          <v-radio-group class="ma-0 pa-0" hide-details dense row v-model="partner">
            <v-radio :value=false label="Клиент"/>
            <v-radio :value=true label="Партнер"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5 row no-gutters">
          <v-col cols="2" class="row no-gutters justify-end">
            <span class="caption">Завершено</span>
          </v-col>
          <v-col cols="2" class="row no-gutters justify-center">
            <span class="caption pl-2">Всего</span>
          </v-col>
          <v-col cols="1" class="row no-gutters justify-center">
            <span class="caption pl-1">Безнал</span>
          </v-col>
          <v-col cols="2" class="row no-gutters justify-center">
            <span class="caption">Цена</span>
          </v-col>
          <v-col cols="1" class="row no-gutters justify-center">
            <span class="caption">комиссия</span>
          </v-col>
          <v-col cols="2" class="row no-gutters justify-center">
            <span class="caption">Доставка</span>
          </v-col>
          <v-col cols="2" class="row no-gutters justify-center">
            <span class="caption pr-1">Итого</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5">
          <div v-for="order in datesList" :key="order.finished_at" class="row no-gutters grey lighten-4 mx-1 mb-1">
            <v-col cols="2" class="d-flex justify-center align-center text-no-wrap">
              <span class="caption">{{ order.finished_at | dateFormat }}</span>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center text-no-wrap">
              <span class="caption">{{ order.count }}</span>
            </v-col>
            <v-col cols="1" class="d-flex justify-center align-center text-no-wrap">
              <span class="caption">{{ order.cashless }}</span>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center text-no-wrap">
              <span class="caption">{{ order.store }} с.</span>
            </v-col>
            <v-col cols="1" class="d-flex justify-center align-center text-no-wrap">
              <span class="caption">{{ order.store_commission }} с.</span>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center text-no-wrap">
              <span class="caption">{{ order.delivery }} с.</span>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center text-no-wrap">
              <span class="caption">{{ order.total }} с.</span>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="col col-lg-7 col-md-7 col-sm-7 pr-3">
          <chart v-if="loaded" class="py-0"></chart>
          <barchartbycount v-if="loaded" class="mt-5"></barchartbycount>
        </v-col>
      </v-row>
    </div>
    <div v-if="reportType === 'storeDates'">
      <v-row class="mx-0 mb-4 mt-0">
        <v-col>
          <v-radio-group class="ma-0 pa-0" hide-details dense row v-model="partner">
            <v-radio :value=false label="Клиент"/>
            <v-radio :value=true label="Партнер"/>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row dense class="hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Заведение</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption pl-2">Всего</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Безнал</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Цена зав.</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Комиссия</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Курьер</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption pr-1">Всего</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="caption">Завершено</span>
        </v-col>
      </v-row>
      <v-row no-gutters v-for="(order, index) in storesDatesList" :key="index" class="grey lighten-4 mx-1 mb-1">
        <v-col cols="12" class="col col-lg-2 col-md-2 col-sm-2 d-flex justify-center align-center text-no-wrap">
          <span class="caption">{{ order.name }}</span>
        </v-col>
        <v-col cols="1" class="d-flex justify-center align-center">
          <span class="caption">{{ order.count }}</span>
        </v-col>
        <v-col cols="1" class="d-flex justify-center align-center">
          <span class="caption">{{ order.cashless }}</span>
        </v-col>
        <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
          <span class="caption">{{ order.store }} с.</span>
        </v-col>
        <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
          <span class="caption">{{ order.store_commission }} с.</span>
        </v-col>
        <v-col cols="2" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
          <span class="caption">{{ order.delivery }} с.</span>
        </v-col>
        <v-col cols="3" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
          <span class="caption">{{ order.total }} с.</span>
        </v-col>
        <v-col cols="3" class="col col-lg-1 col-md-1 col-sm-1 d-flex justify-center align-center">
          <span class="caption">{{ order.o_finished_at | dateFormat }}</span>
        </v-col>
      </v-row>
      <v-pagination
          v-model="currentPage"
          :length="paginationCount"
          total-visible="9"
          @input="fetchStoresDates">
      </v-pagination>
    </div>
    <div v-if="reportType === 'missed'">
      <v-row dense class="hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Заведение</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Телефон кл.</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">ЦенаЗав.</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">КомЗав.</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption">Курьер</span>
        </v-col>
        <v-col cols="1" class="row no-gutters justify-center">
          <span class="text-caption pr-2">Всего</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="text-caption">Дата</span>
        </v-col>
      </v-row>
      <v-row no-gutters v-for="(order, index) in missed" :key="index" class="grey lighten-4 mx-1 mb-1">
        <v-col cols="12" class="col col-lg-2 col-md-2 sm-2 d-flex justify-center align-center text-no-wrap">
          <span class="caption">{{ order.name }}</span>
        </v-col>
        <v-col cols="12" class="col col-lg-2 col-md-2 sm-2 d-flex justify-center align-center">
          <span class="caption">{{ order.client | normalizePhone }}</span>
        </v-col>
        <v-col cols="2" class="col col-lg-1 col-md-1 sm-1 justify-center d-flex align-center">
          <span class="caption">{{ order.store }} с.</span>
        </v-col>
        <v-col cols="2" class="col col-lg-1 col-md-1 sm-1 justify-center d-flex align-center">
          <span class="caption">{{ order.store_commission }} с.</span>
        </v-col>
        <v-col cols="2" class="col col-lg-1 col-md-1 sm-1 justify-center d-flex align-center">
          <span class="caption">{{ order.delivery }} с.</span>
        </v-col>
        <v-col cols="2" class="col col-lg-1 col-md-1 sm-1 justify-center d-flex align-center">
          <span class="caption">{{ order.total }} с.</span>
        </v-col>
        <v-col cols="4" class="col col-lg-2 col-md-2 sm-2 d-flex justify-center align-center">
          <span class="caption">{{ order.to_char }}</span>
        </v-col>
      </v-row>
    </div>
    <div v-if="reportType === 'drivers'">
      <v-row dense class="hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Заведение</span>
        </v-col>
        <v-col cols="1" class="row no-gutters">
          <v-col cols="6" class="row no-gutters justify-center">
            <span class="caption">Всего</span>
          </v-col>
          <v-col cols="6" class="row no-gutters justify-center">
            <span class="caption">Безнал</span>
          </v-col>
        </v-col>
        <v-col cols="2" class="row no-gutters">
          <v-col cols="4" class="row no-gutters justify-start">
            <span class="caption">ЦенаЗав.</span>
          </v-col>
          <v-col cols="4" class="row no-gutters justify-start">
            <span class="caption pl-1">Курьер</span>
          </v-col>
          <v-col cols="4" class="row no-gutters justify-center">
            <span class="caption pr-2">Всего</span>
          </v-col>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5">
          <div v-for="(order, index) in driversList" :key="index" class="row no-gutters grey lighten-4 mx-1 mb-1">
            <v-col cols="12" class="col col-lg-5 col-md-5 col-sm-5 d-flex align-center justify-center text-no-wrap">
              <span class="caption">{{ order.name }}</span>
            </v-col>
            <v-col cols="4" class="col-lg-2 col-md-2 col-sm-2 d-flex align-center row no-gutters justify-center">
              <v-col cols="6" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.count }}</span>
              </v-col>
              <v-col cols="6" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.cashless }}</span>
              </v-col>
            </v-col>
            <v-col cols="8" class="col col-lg-5 col-md-5 col-sm-5 row no-gutters d-flex align-center">
              <v-col cols="4" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.store }} с.</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.delivery }} с.</span>
              </v-col>
              <v-col cols="4" class="row no-gutters justify-center text-no-wrap">
                <span class="caption">{{ order.total }} с.</span>
              </v-col>
            </v-col>
          </div>
        </v-col>
        <v-col cols="12" class="col col-lg-7 col-md-7 col-sm-7 row no-gutters justify-center">
          <piechartdrivers v-if="loaded" class="pie"></piechartdrivers>
        </v-col>
      </v-row>
    </div>
    <div v-if="reportType === 'transactions'">
      <v-row dense class="hidden-xs-only">
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Имя</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Телефон</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Тип транзакции</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption">Сумма</span>
        </v-col>
        <v-col cols="2" class="row no-gutters justify-center">
          <span class="caption pl-1">Дата</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="col">
          <div v-for="(order, index) in transactionsList" :key="index" class="row no-gutters grey lighten-4 mx-1 mb-1">
            <v-col cols="2" class="row no-gutters justify-center text-no-wrap">
              <span class="caption">{{ order.name }}</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center text-no-wrap">
              <span class="caption">{{ order.phone }}</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center text-no-wrap">
              <span class="caption">{{ order.refill_method.refill_type }}</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center text-no-wrap">
              <span class="caption">{{ order.amount }} с.</span>
            </v-col>
            <v-col cols="2" class="row no-gutters justify-center text-no-wrap">
              <span class="caption">{{ order.dt_finished_at | dateFormat }}</span>
            </v-col>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script>
import format from "date-fns/format";
import axios from "@/services/axios";
import chart from './barchart.vue';
import piechart from './piechart.vue';
import barchartbycount from './barchartcount.vue';
import piechartdrivers from "./piechartdrivers";

export default {
  components: {chart, piechart, barchartbycount, piechartdrivers},
  data: () => ({
    pieChartDriversData: null,
    pieChartDriversOptions: null,
    chartdata: null,
    chartDataByCount: null,
    pieChartData: null,
    options: null,
    optionsByCount: null,
    pieChartOptions: null,
    loaded: false,
    limit: 31,
    storeLimit: 300,
    currentPage: 1,
    week: 1,
    onlyDate: true,
    formatted: 'll',
    reportType: 'orders',
    partner: false,
    temp: true,
    date: {},
  }),

  computed: {
    ordersList() {
      return this.$store.getters["reports/ordersList"]
    },
    ordersCount() {
      return this.$store.getters["reports/ordersCount"]
    },
    transactionsList() {
      return this.$store.getters["reports/transactionsList"]
    },

    storesList() {
      return this.$store.getters["reports/storesList"]
    },
    storesCount() {
      return this.$store.getters["reports/storesCount"]
    },

    datesList() {
      return this.$store.getters["reports/datesList"]
    },
    datesCount() {
      return this.$store.getters["reports/datesCount"]
    },

    storesDatesList() {
      return this.$store.getters["reports/storesDatesList"]
    },
    storesDatesCount() {
      return this.$store.getters["reports/storesDatesCount"]
    },

    missed() {
      return this.$store.getters["reports/missed"]
    },
    missedCount() {
      return this.$store.getters["reports/missedCount"]
    },

    driversList() {
      return this.$store.getters["reports/driversList"]
    },
    driversCount() {
      return this.$store.getters["reports/driversCount"]
    },

    paginationCount() {
      if (this.reportType === 'orders' && this.ordersList && this.count) {
        return Math.ceil(this.ordersCount / this.limit)
      }
      if (this.reportType === 'stores' && this.storeList && this.count) {
        return Math.ceil(this.storesCount / this.storeLimit)
      }
      if (this.reportType === 'dates' && this.datesList && this.count) {
        return Math.ceil(this.datesCount / this.limit)
      }
      if (this.reportType === 'storeDates' && this.storesDatesList && this.count) {
        return Math.ceil(this.storesDatesCount / this.limit)
      }
      return 0
    },
    count() {
      if (this.reportType === 'orders') {
        return this.ordersCount;
      }
      if (this.reportType === 'stores') {
        return this.storesCount;
      }
      if (this.reportType === 'dates') {
        return this.datesCount;
      }
      if (this.reportType === 'storeDates') {
        return this.storesDatesCount;
      }
      if (this.reportType === 'missed') {
        return this.missedCount;
      }
      if (this.reportType === 'drivers') {
        return this.driversCount;
      } else {
        return 0
      }
    }
  },

  methods: {
    filter() {
      this.currentPage = 1;

      if ((this.reportType === 'orders') && this.date && this.date.start && this.date.end) {
        this.fetchOrders();
      }
      if ((this.reportType === 'stores') && this.date && this.date.start && this.date.end) {
        this.fetchStores();
      }
      if ((this.reportType === 'dates') && this.date && this.date.start && this.date.end) {
        this.fetchDates();
      }
      if ((this.reportType === 'storeDates') && this.date && this.date.start && this.date.end) {
        this.fetchStoresDates();
      }
      if ((this.reportType === 'missed') && this.date && this.date.start && this.date.end) {
        this.fetchMissed();
      }
      if ((this.reportType === 'drivers') && this.date && this.date.start && this.date.end) {
        this.fetchDrivers();
      }
      if ((this.reportType === 'transactions') && this.date && this.date.start && this.date.end) {
        this.fetchTransactions();
      }
    },

    fetchOrders() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit}
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.$store.dispatch("reports/fetchOrders", queryString);
    },

    fetchStores() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.storeLimit}
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.partner = this.partner
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.loaded = true;
      this.$store.dispatch("reports/fetchStores", queryString);
    },

    fetchDates() {
      this.loaded = false;
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit}
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.partner = this.partner
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.loaded = true;
      this.$store.dispatch("reports/fetchDates", queryString);
    },

    fetchStoresDates() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit}
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.partner = this.partner
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.$store.dispatch("reports/fetchStoresDates", queryString)
    },

    fetchMissed() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit}
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.$store.dispatch("reports/fetchMissed", queryString)
    },

    fetchDrivers() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit}
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.loaded = true;
      this.$store.dispatch("reports/fetchDrivers", queryString)
    },

    fetchTransactions() {
      let payload = {}
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      this.$store.dispatch("reports/fetchTransactions", queryString);
    },

    download() {
      if (this.reportType === 'orders') {
        this.downloadOrders();
      }
      if (this.reportType === 'stores') {
        this.downloadStores();
      }
      if (this.reportType === 'dates') {
        this.downloadDates();
      }
      if (this.reportType === 'storeDates') {
        this.downloadStoreDates();
      }
      if (this.reportType === 'missed') {
        this.downloadMissed();
      }
      if (this.reportType === 'drivers') {
        this.downloadDrivers();
      }
      if (this.reportType === 'transactions') {
        this.downloadTransactions();
      }
    },

    downloadOrders() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.download = true
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/reports/orders?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'orders.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    downloadStores() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.partner = this.partner
        payload.download = true
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/reports/stores?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'stores.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    downloadDates() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.partner = this.partner
        payload.download = true
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/reports/dates?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'dates.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    downloadStoreDates() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.partner = this.partner
        payload.download = true
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/reports/stores_dates?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'storeDates.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    downloadMissed() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.download = true
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/reports/missed?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'missedOrders.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    downloadDrivers() {
      let offset = (this.currentPage - 1) * this.limit;
      let payload = {offset: offset, limit: this.limit};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.download = true
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/reports/drivers?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'drivers.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },

    downloadTransactions() {
      let payload = {};
      if (this.date && this.date.start && this.date.end) {
        payload.start = format(new Date(this.date.start), "yyyy-MM-dd")
        payload.end = format(new Date(this.date.end), "yyyy-MM-dd")
        payload.download = true
      }
      let queryString = Object.keys(payload).map(key => key + '=' + payload[key]).join('&');
      axios({
        url: "/reports/transactions?" + queryString,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'transactions.xlsx'); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },

  filters: {
    normalizePhone: (p) => {
      if (!p) {
        return ""
      }
      let result = "";
      let phones = p.split(",");

      phones.forEach(phone => {
        phone = phone.replaceAll("+", "").replaceAll(" ", "")
        let temp = "+"
        for (let i = 0; i < phone.length; i++) {
          if (i % 3 === 0) {
            temp = temp + " "
          }
          temp = temp + phone[i]
        }
        result = result + temp + ","
      })

      return result.substr(0, result.length - 1)
    },
    timeFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd HH:mm")
    },
    dateFormat(d) {
      if (!d) {
        return ""
      }
      return format(new Date(d), "yyyy-MM-dd")
    },
  }
}
</script>

<style scoped>
.pie {
  width: 800px;
  height: 800px;
}
</style>
